:root {
  --primary-bg: #ffffff;
  --primary-text: #000000;
  --primary-nav-links: #808080;
  --primary-description: #5a5a5a;
  --timeline-background: #E8E8E8;
  --timeline-text: #000000;
  --project-hover-desription: #ffffff;
  --latest-blogs-bg: #E8E8E8;
  --latest-blogs-text: #000000;
}

html[theme="dark"] {
  --primary-bg: #000000;
  --primary-text: #ffffff;
  --primary-nav-links: #ffffff;
  --primary-description: #ffffff;
  --timeline-background: #181818;
  --timeline-text: #ffffff;
  --project-hover-desription: #ffffff;
  --latest-blogs-bg: #181818;
  --latest-blogs-text: #ffffff;
}

@font-face {
  font-family: "inter";
  src: url(../fonts/Inter-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "inter-semibold";
  src: url(../fonts/Inter-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "inter-bold";
  src: url(../fonts/Inter-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "generic-g50";
  src: url(../fonts/Generic-G50-CC-Typic-DEMO.otf) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins";
  src: url(../fonts/Poppins-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-semibold";
  src: url(../fonts/Poppins-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-bold";
  src: url(../fonts/Poppins-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "poppins-black";
  src: url(../fonts/Poppins-Black.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  background: var(--primary-bg);
  font-family: "poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "poppins";
}

::-moz-selection {
  /* Code for Firefox */
  color: var(--primary-bg);
  background: var(--primary-text);
}

::selection {
  color: var(--primary-bg);
  background: var(--primary-text);
}

a {
  outline: none !important;
  text-decoration: none !important;
}

li {
  list-style-type: none;
}

body::-webkit-scrollbar {
  width: 5px !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px var(--primary-bg);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--primary-text);
  outline: 1px solid var(--primary-bg);
}

// @media screen and (max-width: 1440px) {
//   body {
//     font-size: 14.5px !important;
//   }
// }

@media screen and (max-width: 1440px) {
  html {
    zoom: 0.9;
  }
}

@import "../css/main.scss";

.navbar {
  width: 100%;
  height: 6rem;
  top: 0;
  color: var(--primary-text);
  overflow: hidden;
  // border-bottom: 1px #000000 solid;
}

.logo-section {
  margin-left: 200px;
  color: var(--primary-text);
  padding-top: 1.5rem;

  a {
    img {
      height: 3.8rem;
      width: 4rem;
    }
  }
}

html[theme="dark"] {
  .logo-section {
    margin-left: 200px;
    color: var(--primary-text);
    padding-top: 1.5rem;

    a {
      img {
        height: 3.8rem;
        width: 4rem;
        filter: invert(1);
      }
    }
  }
}

.navbar-links {
  display: flex;
  margin-left: auto;
  padding-top: 2rem;
  margin-right: 7rem;
}

.link-items {
  padding-top: 0.5rem;
  color: var(--primary-description);
  font-family: "poppins", sans-serif;
  a {
    padding-right: 3rem;
    border: none;
    outline: none;
    text-decoration: none;
  }
}

.theme-toggle {
  padding-top: 0.3rem;
  a {
    img {
      color: var(--primary-text);
      width: 2rem;
      height: 1.5rem;
    }
  }
}

html[theme="dark"] {
  .theme-toggle {
    padding-top: 0.3rem;
    a {
      img {
        width: 2rem;
        height: 1.5rem;
        filter: invert(1);
      }
    }
  }
}
